const api = process.env.REACT_APP_API;

//const API_ID = process.env.REACT_APP_API_ID
//const APP_KEY = process.env.REACT_APP_APP_KEY




/*const headers = {
    'Accept': 'application/json',
    'Authorization': token
}*/

//USER: vcc-dynamo
let accessKeyId = "AKIAWPZ4IK7E5KAJZUWC"
let secretAccessKey = "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"
let region = "us-east-1"


const AWS = require('aws-sdk');



function headers() {
    let headerapi = {
        'Accept': 'application/json',
        //'Authorization': 'Bearer U2FsdGVkX183xX8sN41+sif7knYwsPVVlANsrI2Wt1XO2PquxFvZK/SkJyfFllaT0Xj+Ciu2TnyNpCHT5Fl0K2lnqEFv0cfwQpwD/GjBhNyJaj7PoVBByiKpAqt33w4THjfMLqr8MY4UiQQ9NYPfKGHMW+wsiPrySjbd0mT2ciVBl20L9bDTJBOnMB/xQhvJubrjL8D910dyXPlXdm04ID605b1dDVpNnKm6B6j3jV7l4TNaO4Wp2z/HU0c9lxUpHqvUQnOHvejf6FmyNByqPT91zS8K5k4oGUaf+nDKeLB+1G27WC2FL4WboO2SO5eooeuT+pKr6e8raEkgPgzktp/2uG9BwjqRgTGK6dyBrMIQObIqYfb/jyeakh5FHfJt5rIVhpF5EzhpEfxmeHVWT1JjgkjWDB2ipasErz7GZKHjG8dCP1T45DJo77ZbH7zRLbdNQwfO+ZeQPPxjTEXg1bXAsV7tzc0/+r3Ye50Un3A='
        'Authorization': 'Bearer ' + localStorage.getItem('tokenAPI')
    }

    return headerapi
}


export const postToken = (FormData) =>
    fetch(`${api}/token/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(FormData)
    }).then(res => res.json());



export const saveOrdemCargaParaASair = (entidade) =>
    fetch(`${api}/ordemdecarga/asair/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const saveOrdemCargaDevolucao = (entidade) =>
    fetch(`${api}/ordemdecarga/devolucao/inicio/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const saveOrdemCargaDevolucaoFim = (entidade) =>
    fetch(`${api}/ordemdecarga/devolucao/fim/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllMotivos = () =>
    fetch(`${api}/ordemdecargamotivos/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());







export const getAllVendedores = () =>
    fetch(`${api}/ordemdecargavendedores/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




export const getAllOrdemCarga = (option) =>
    fetch(`${api}/ordemdecarga/${option}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());







export const getAllOrdemCargaPedidos = () =>
    fetch(`${api}/ordemdecargapedidos/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const saveOrdemCargaEntrega = (PedidoList) =>
    fetch(`${api}/ordemdecarga/entrega/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(PedidoList)
    }).then(res => res.json());

export const saveOrdemCargaFrete = (pedido) =>
    fetch(`${api}/ordemdecarga/frete/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pedido)
    }).then(res => res.json());

export const saveOrdemCargaEmRota = (PedidoList) =>
    fetch(`${api}/ordemdecarga/emrota/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(PedidoList)
    }).then(res => res.json());



export const saveOrdemCargaMotivo = (PedidoList) =>
    fetch(`${api}/ordemdecarga/motivo/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(PedidoList)
    }).then(res => res.json());

export const saveOrdemCargaASairNSaiu = (Pedido) =>
    fetch(`${api}/ordemdecarga/asair/motivo/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(Pedido)
    }).then(res => res.json());



export const getAllDevolucao = () =>
    fetch(`${api}/ordemdecargadevolucao/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postLogin = (FormData) =>
    fetch(`${api}/login/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(FormData)
    }).then(res => res.json());



export const getLoginMenu = (userid) =>
    fetch(`${api}/menu/${userid}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());



export const getAllRoboDePrecosConfiguracoes = () =>
    fetch(`${api}/robodeprecos/configuracoes/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRoboDePrecosModificados = () =>
    fetch(`${api}/robodeprecos/modificados/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllRoboDePrecosForaDosLimites = () =>
    fetch(`${api}/robodeprecos/foradoslimites/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllRoboDePrecosMantidos = () =>
    fetch(`${api}/robodeprecos/mantidos/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRoboDePrecosMonitorados = () =>
    fetch(`${api}/robodeprecos/monitorados/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRoboDePrecosGeral = () =>
    fetch(`${api}/robodeprecos/geral/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




export const getAllGraficoDeVendas = () =>
    fetch(`${api}/graficodevendas/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllGraficoDeVendasDadosFaturar = () =>
    fetch(`${api}/graficodevendas/listadadosfaturar/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllGraficoDeVendasDadosPagar = () =>
    fetch(`${api}/graficodevendas/listadadospagar/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getPesquisaTarefas = (userid) =>
    fetch(`${api}/pesquisa/tarefas/${userid}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getPesquisaCheck = (tarefaid, login) =>
    fetch(`${api}/pesquisa/tarefas/${tarefaid}/${login}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());



export const postPesquisaSave = (TarefaEntity) =>
    fetch(`${api}/pesquisa/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(TarefaEntity)
    }).then(res => res.json());


export const postRoboPrecoConfigSave = (RoboPrecosConfigEntity) =>
    fetch(`${api}/roboprecos/config/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(RoboPrecosConfigEntity)
    }).then(res => res.json());


export const postRoboDePrecosStatus = () =>
    fetch(`${api}/robodeprecosstatus/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
    }).then(res => res.json());


export const getRoboDePrecosStatus = () =>
    fetch(`${api}/robodeprecosstatus/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




export const postDespesasDeViagensMesmoDiaRemove = (entidade) =>
    fetch(`${api}/controledeviagens/mesmodia/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getAllDespesasDeViagensSaidas = () =>
    fetch(`${api}/controledeviagens/saidas/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




export const getAllListAjudante = () =>
    fetch(`${api}/controledeviagens/ajudante/lista/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postAjudanteAtualiza = (entidade) =>
    fetch(`${api}/controledeviagens/ajudante/atualiza/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postAjudanteInsere = (entidade) =>
    fetch(`${api}/controledeviagens/ajudante/insere/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());






export const getAllDespesasDeViagensMotoristas = () =>
    fetch(`${api}/controledeviagens/motoristas/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllDespesasDeViagensAjudantes = () =>
    fetch(`${api}/controledeviagens/ajudantes/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

//postDespesasDeViagensSaidasSave

export const postDespesasDeViagensSaidasSave = (entidade) =>
    fetch(`${api}/controledeviagens/saidas/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllDespesasDeViagensChegadas = () =>
    fetch(`${api}/controledeviagens/chegadas/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postDespesasDeViagensChegadas = (entidade) =>
    fetch(`${api}/controledeviagens/chegadas/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postDespesasDeViagensCancel = (entidade) =>
    fetch(`${api}/controledeviagens/cancel/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getAllDespesasDeViagensFeriados = () =>
    fetch(`${api}/controledeviagens/feriados/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllDespesasDeViagensRecibos = () =>
    fetch(`${api}/controledeviagens/recibos/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const PostAllDespesasDeViagensAssinaturasFilter = (entidade) =>
    fetch(`${api}/controledeviagens/assinaturas/filter`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllDespesaAdicional = () =>
    fetch(`${api}/controledeviagens/adicional/list/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postDespesaAdicional = (entidade) =>
    fetch(`${api}/controledeviagens/adicional/update/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postDespesasDeViagensRecibos = (entidade) =>
    fetch(`${api}/controledeviagens/recibos/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getAllDespesasDeViagensAssinaturas = () =>
    fetch(`${api}/controledeviagens/assinaturas/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postDespesasDeViagensAssinaturas = (entidade) =>
    fetch(`${api}/controledeviagens/assinaturas/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllDespesasDeViagensFinanceiroAProcessar = () =>
    fetch(`${api}/controledeviagens/financeiroprocessar/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllDespesasDeViagensFinanceiroGerado = () =>
    fetch(`${api}/controledeviagens/financeirogerado/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postDespesasDeViagensFinanceiroGeradoSave = (entidade) =>
    fetch(`${api}/controledeviagens/financeirogerado/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postDespesasDeViagensFinanceiroGeradoOCSave = (entidade) =>
    fetch(`${api}/controledeviagens/financeirogeradooc/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postDespesasDeViagensSearch = (entidade) =>
    fetch(`${api}/controledeviagens/rj/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAdiantamentoCria = (oc) =>
    fetch(`${api}/controledeviagens/adiantamento/cria/${oc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postAdiantamentoCria = (entidade) =>
    fetch(`${api}/controledeviagens/adiantamento/cria`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());




export const getAdiantamentoList = (pagina) =>
    fetch(`${api}/controledeviagens/adiantamento/lista/${pagina}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());



export const getAdiantamentoFinList = () =>
    fetch(`${api}/controledeviagens/adiantamento/financeiro/lista/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postAdiantamentoFinAprova = (entidade) =>
    fetch(`${api}/controledeviagens/adiantamento/financeiro/aprova/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllConsultoresFechamento = () =>
    fetch(`${api}/consultores/fechamento/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllConsultoresFechamento90 = () =>
    fetch(`${api}/consultores/fechamento90/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllConsultores = () =>
    fetch(`${api}/consultores/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllCidades = () =>
    fetch(`${api}/consultores/cidades/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postConsultoresSearch = (entidade) =>
    fetch(`${api}/consultores/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllRenegociacaoConfiguracoes = () =>
    fetch(`${api}/renegociacaodedividas/configuracoes/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRenegociacaoDividas = (codparc) =>
    fetch(`${api}/renegociacaodedividas/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postRenegociacaoSaveConfiguracoes = (entidade) =>
    fetch(`${api}/renegociacaodedividas/configuracoes/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postRenegociacaoSave = (entidade) =>
    fetch(`${api}/renegociacaodedividas/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postRenegociacaoSimulacao = (entidade) =>
    fetch(`${api}/renegociacaodedividas/simulacao/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postRenegociacaoConsultaCancel = (entidade) =>
    fetch(`${api}/renegociacaodedividas/cancel/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getAllRenegociacaoConsulta = (codparc) =>
    fetch(`${api}/renegociacaodedividas/consulta/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllRenegociacaoCredito = (codparc) =>
    fetch(`${api}/renegociacaodedividas/credito/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getMarketPlacesConciliacaoImportacoes = () =>
    fetch(`${api}/marketplaces/conciliacao/import/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postMarketPlacesConciliacao = (entidade) =>
    fetch(`${api}/marketplaces/conciliacao/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postMarketPlacesConciliacaoProcess = (entidade) =>
    fetch(`${api}/marketplaces/conciliacao/process/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

///marketplaces/conciliacao/  getPesquisaTarefas  
export const getMarketPlacesConciliacaoDetalhes = (nurepassemkp) =>
    fetch(`${api}/marketplaces/conciliacao/detalhes/${nurepassemkp}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postMarketPlacesConciliacaoRepasse = (entidade) =>
    fetch(`${api}/marketplaces/conciliacao/repasse/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postMarketPlacesConciliacaoDelete = (entidade) =>
    fetch(`${api}/marketplaces/conciliacao/delete/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());




export const postMarketPlacesPedidos = (entidade) =>
    fetch(`${api}/marketplaces/pedidos/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getMarketPlacesParceiroCidade = (codparc) =>
    fetch(`${api}/marketplaces/parceiro/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




export const postMarketPlacesParceiroCidade = (entidade) =>
    fetch(`${api}/marketplaces/parceiro/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());




export const postMarketPlacesDevoluçãoList = (entidade) =>
    fetch(`${api}/marketplaces/devolucao/list/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postMarketPlacesDevoluçãoCria = (entidade) =>
    fetch(`${api}/marketplaces/devolucao/criar/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getMarketPlacesDevoluçãoModalInfo = (nufin) =>
    fetch(`${api}/marketplaces/devolucao/modal/${nufin}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getMarketPlacesDevoluçãoAnalise = (devolucaoid) =>
    fetch(`${api}/marketplaces/devolucao/analise/${devolucaoid}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postMarketPlacesDevoluçãoEdit = (entidade) =>
    fetch(`${api}/marketplaces/devolucao/edit/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());




export const getAllComparativoProdutos = () =>
    fetch(`${api}/comparativoprodutos`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllComissoes = () =>
    fetch(`${api}/comissoes`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postComissoesSave = (entidade) =>
    fetch(`${api}/comissoes/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllComissoesVendedores = () =>
    fetch(`${api}/comissoes/vendedores`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllComissoesRelatorio = () =>
    fetch(`${api}/comissoes/relatorio`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllCadastroDeProdutosExport = () =>
    fetch(`${api}/cadastrodeprodutos/export/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getLeroyCadastroDeProdutosExport = () =>
    fetch(`${api}/cadastrodeprodutos/export/leroy/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postAllCadastroDeProdutosInfoSearch = (entidade) =>
    fetch(`${api}/cadastrodeprodutos/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postSaveCadastroDeProdutosInfo = (entidade) =>
    fetch(`${api}/cadastrodeprodutos/info/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());
export const postSaveCadastroDeProdutosInfoB2B = (entidade) =>
    fetch(`${api}/cadastrodeprodutos/infob2b/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getAllProcessamentosLibMarketPlace = () =>
    fetch(`${api}/processamentos/libmarketplace/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postSaveProcessamentosMarketPlace = (entidade) =>
    fetch(`${api}/processamentos/libmarketplace/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllProcessamentosDescontoFbits = () =>
    fetch(`${api}/processamentos/descontofbits/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }

    }).then(res => res.json());


export const getAllProcessamentosStatus = () =>
    fetch(`${api}/processamentos/status/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllProcessamentosParceiro = (codparc) =>
    fetch(`${api}/processamentos/parceiro/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postSaveProcessamentosParceiro = (entidade) =>
    fetch(`${api}/processamentos/parceiro/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllAgrupador = (codprod) =>
    fetch(`${api}/agrupador/${codprod}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postAgrupador = (entidade) =>
    fetch(`${api}/agrupador/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllBlackFriday = () =>
    new Promise(function (resolve, reject) {


        AWS.config.update({ accessKeyId: accessKeyId, secretAccessKey: secretAccessKey, region: region })

        var client = new AWS.DynamoDB.DocumentClient();



        var params = {
            TableName: 'BlackFriday'
            , FilterExpression: 'campanha = :p_campanha'
            , ExpressionAttributeValues: {
                ':p_campanha': new Date().getFullYear().toString()
            }
        };

        client.scan(params, function (err, data) {
            if (err) {
                resolve([]);
            } else {

                resolve(data.Items);
            }
        });
    }
    )



export const getAllRepresentantesFechamento = () =>
    fetch(`${api}/representantes/fechamento/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllRepresentantes = () =>
    fetch(`${api}/representantes/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRepresentantesCidades = () =>
    fetch(`${api}/representantes/cidades/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postRepresentantesSearch = (entidade) =>
    fetch(`${api}/representantes/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postInstaladoresSearch = (entidade) =>
    fetch(`${api}/instaladores/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postSerasaSearch = (entidade) =>
    fetch(`${api}/serasa/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postOrcamentosSearch = (entidade) =>
    fetch(`${api}/orcamentos/search/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());






export const getMercadoLivreToken = (codigo) =>
    fetch(`${api}/mercadolivre/token/${codigo}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postMercadoLivreDimensoes = (entidade) =>
    fetch(`${api}/mercadolivre/dimensoes/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postMercadoLivreAnuncio = (entidade) =>
    fetch(`${api}/mercadolivre/anuncio/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getMercadoLivrePendentes = (codigo) =>
    fetch(`${api}/mercadolivre/pendentes/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getMercadoLivreEnviados = (codigo) =>
    fetch(`${api}/mercadolivre/enviados/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postMercadoLivreNF = (pedidos) =>
    fetch(`${api}/mercadolivre/nf/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pedidos)
    }).then(res => res.json());




export const saveOrdemCargaEmRotaPrazos = (Pedido) =>
    fetch(`${api}/ordemdecarga/emrota/prazos/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(Pedido)
    }).then(res => res.json());


export const postBankArquivoML = (dados) =>
    fetch(`${api}/bank/ml/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dados)
    }).then(res => res.json());

export const getBankAquivoML = () =>
    fetch(`${api}/bank/ml/arquivo/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getPenteFino = () =>
    fetch(`${api}/pentefino/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postAllOrdemCargaPorVend = (busca) =>
    fetch(`${api}/ordemdecargaporvendedor/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(busca)
    }).then(res => res.json());


/*
    export const postAllOrdemCargaPedidosPorVend = (busca) =>
    fetch(`${api}/ordemdecargapedidos/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(busca)
    }).then(res => res.json());

*/



export const postAllOrdemCargaAutoPorVend = (busca) =>
    fetch(`${api}/ordemdecargaautoporvendedor/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(busca)
    }).then(res => res.json());


export const saveOrdemCargaAuto = (entidade) =>
    fetch(`${api}/ordemdecarga/auto/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postOrdemCargaLigar = (entidade) =>
    fetch(`${api}/ordemdecarga/ligar/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAllB2BParceiro = (codparc) =>
    fetch(`${api}/b2b/parceiro/${codparc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRelatorio = () =>
    fetch(`${api}/b2b/relatorio/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postB2BCheckedChamou = (entidade) =>
    fetch(`${api}/b2b/relatorio/chamou`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postB2BCheckedConverteu = (entidade) =>
    fetch(`${api}/b2b/relatorio/converteu`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postSaveB2BParceiro = (entidade) =>
    fetch(`${api}/b2b/parceiro/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getAppEntregasNF = (nf) =>
    fetch(`${api}/appentregas/nf/${nf}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllOrdemCargaAppEntregas = (ordemcarga) =>
    fetch(`${api}/ordemdecarga/appentregas/${ordemcarga}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getAllTrackersPainel = () =>
    fetch(`${api}/trackers/painel/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postSaveTrackersLog = (entidade) =>
    fetch(`${api}/trackers/log/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getTrackersLogReasons = () =>
    fetch(`${api}/trackers/reasons/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getTrackersDifferentCars = () =>
    fetch(`${api}/trackers/diffcars/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const postDescontoFBitsIndividual = (entidade) =>
    fetch(`${api}/processamentos/descontofbits/individual/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllListUsuariosMGR = () =>
    fetch(`${api}/permissoes/usuarios/lista/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postUsuariosMGRAtualiza = (entidade) =>
    fetch(`${api}/permissoes/usuarios/atualiza/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postUsuarioInsere = (entidade) =>
    fetch(`${api}/permissoes/usuarios/insere/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postUsuarioCopiaPermissoes = (entidade) =>
    fetch(`${api}/permissoes/usuarios/copy/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postUsuarioSalvaPermissoes = (entidade) =>
    fetch(`${api}/permissoes/usuarios/save/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getAllListPixFast = (entidade) =>
    fetch(`${api}/pix/lista/fast/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFiltroPixFast = (entidade, page) =>
    fetch(`${api}/pix/filtro/fast/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade, page)
    }).then(res => res.json());

export const getDetalhePixFast = (entidade) =>
    fetch(`${api}/pix/detalhe/fast/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getMonitoramentoErrors = () =>
    fetch(`${api}/home/monitoramento/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getKpiVisitas = (entidade) =>
    fetch(`${api}/kpivistas/lista/`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());




//---------------- ROTDAS DA PAGINA DE ASSISTENCIA ----------------
export const getListAssistenciaMGR = (entidade) =>
    fetch(`${api}/assistencia/lista/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getListAssistenciaCardMGR = (entidade) =>
    fetch(`${api}/assistencia/lista/card`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getListFiltroAssistenciaMGR = (entidade) =>
    fetch(`${api}/assistencia/lista/filtro`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getModalAssistenciaMGR = (entidade) =>
    fetch(`${api}/assistencia/modal`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postStatusAssistenciaMGR = (entidade) =>
    fetch(`${api}/assistencia/status`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postRespostaAssistenciaMGR = (entidade) =>
    fetch(`${api}/assistencia/resposta`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postSaveImageVisitaTecnica = (entidade) =>
    fetch(`${api}/assistencia/save/image/visita`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const getOuvidoriaList = (oc) =>
    fetch(`${api}/ouvidoria/lista/${oc}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());



export const postOuvidoriaSave = (entidade) =>
    fetch(`${api}/ouvidoria/save/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postOuvidoriaStatusUpdate = (entidade) =>
    fetch(`${api}/ouvidoria/status/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const getPedidos = (entidade, showOcult) =>
    fetch(`${api}/espacotrack/pedidos/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userid: entidade, showOcult: showOcult })
    }).then(res => res.json());

export const getVendedores = (entidade) =>
    fetch(`${api}/espacotrack/vendedores/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userid: entidade })
    }).then(res => res.json());
export const getParceiro = (nunota, userid) =>
    fetch(`${api}/espacotrack/parceiro/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nunota: nunota, userid: userid })
    }).then(res => res.json());

export const postExcluirNota = (nunota, ordemcarga, userid) =>
    fetch(`${api}/espacotrack/excluirnota/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nunota: nunota, ordemcarga: ordemcarga, userid: userid })
    }).then(res => res.json());
